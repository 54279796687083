import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Downshift from 'downshift'
import { format } from 'date-fns'
import { it, fr, es, de } from 'date-fns/locale'
import { MenuDS, ModalHeaderDS, ModalSelectDS } from '../../CarlaSelect'
import { ButtonEl, El, FlexEl, InputEl } from '../../layout'
import util from '../../../util'
import { FadeModal } from '../../Modal'
import CalendarIcon from '../../../util/images/icon-calendar.svg'
import ClockIcon from '../../../util/images/icon-time.svg'
import DateRangePicker from '../../DatePicker/DateRangePicker'
import TimeSelect from './TimeSelect'
import SessionManager from '../../../services/SessionManager'
import ReactPortal from './../../../components/ReactPortal'
import { withTranslation } from 'react-i18next'

const DateAndTimeContainer = styled(FlexEl)`
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 50px;
`

const DateAndTimeWrapper = styled(FlexEl)`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 4%;
`

export const DateInputContainer = styled(El)`
  width: 50%;

  @media only screen and (min-width: 350px) {
    width: 55%;
  }

  @media only screen and (min-width: 450px) {
    width: 60%;
  }

  @media only screen and (min-width: ${themeGet('breakpoints.1')}px) {
    width: 65%;
  }

  @media only screen and (min-width: 700px) {
    width: 70%;
  }
`

const DateInputWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background: url(${CalendarIcon});
  background-repeat: no-repeat;
  background-color: white;
  background-position: 3% 50%;
  border-radius: 12px;
  padding-right: 3%;
  border: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};

  // mobile date input shadow below
  -webkit-box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
  -moz-box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
  box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
`

const DatePickerWrapper = styled.div`
  min-height: calc(100vh - 110px);
  /* margin-top: 155px; */
  margin-top: 110px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const TimeInputContainer = styled(El)`
  width: 50%;

  @media only screen and (min-width: 350px) {
    width: 45%;
  }

  @media only screen and (min-width: 450px) {
    width: 40%;
  }

  @media only screen and (min-width: ${themeGet('breakpoints.1')}px) {
    width: 35%;
  }

  @media only screen and (min-width: 700px) {
    width: 30%;
  }
`

const TimeInputWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  background: url(${ClockIcon});
  background-repeat: no-repeat;
  background-color: white;
  background-position: 4% 50%;
  border-radius: 12px;
  border: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};

  // mobile time select input shadow
  -webkit-box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
  -moz-box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
  box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
`

const NextButtonWrapper = styled.div`
  width: 100vw;
  height: 15vh;
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 999999;
  padding: 0 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background: ${themeGet('colors.newColorPrimary')};
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.12);

  @media only screen and (min-width: ${themeGet('breakpoints.0')}px) {
    position: static;
  }
`

const DateText = styled(El)`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${themeGet('colors.textBlack')};
`

const NextButton = styled.button`
  background-color: ${themeGet('colors.colorPrimary')};
  border-radius: 12px;
  padding: 14px 25px;
  color: white;
  border: none;
  font-size: 24px;
`

const DateHeaderTitle = styled.div`
  padding-bottom: 8px;
  width: 85%;
`

const country = SessionManager.getUserCountry()
export const timeConverter = (timeObj) => {
  const country = SessionManager.getUserCountry()
  let resultHour
  const resultMin = timeObj.min === 0 || timeObj.min === '00' ? '00' : '30'
  const timeInterval = country && country === 'US' ? (timeObj.hour < 12 ? 'AM' : 'PM') : ''

  if (country && country === 'US') {
    if (timeObj.hour < 12) {
      if (timeObj.hour === 0) resultHour = 12
      else resultHour = timeObj.hour < 10 ? `${timeObj.hour}` : timeObj.hour
    } else {
      resultHour = timeObj.hour !== 12 ? timeObj.hour - 12 : timeObj.hour
    }
  } else {
    if (timeObj.hour < 12) {
      if (timeObj.hour === 0) resultHour = '00'
      else resultHour = timeObj.hour < 10 ? `0${timeObj.hour}` : timeObj.hour
    } else {
      resultHour = timeObj.hour > 23 ? timeObj.hour - 12 : timeObj.hour
    }
  }

  return `${resultHour}:${resultMin} ${timeInterval}`
}

/**
 * @param date {Date}
 * @param time {{hour, min}}
 * @param onDateChange {function}
 * @param onTimeChange {function}
 * @param label {string} pickup time|drop off time
 * @param label {after} DatePicker parameter
 */

const DateFieldWithRange = styled(
  class extends React.Component {
    state = {
      isDateOpen: false,
      isTimeOpen: false
    }

    openDateMenu = () => this.setState({ isDateOpen: true })
    closeDateMenu = () => this.setState({ isDateOpen: false })
    openTimePopUp = () => this.setState({ isTimeOpen: true })
    closeTimePopUp = () => this.setState({ isTimeOpen: false })

    handleNextDate = (e) => {
      e.preventDefault()
      this.closeDateMenu()

      const mobileSearchForm = document.getElementById('MOBILE-SEARCH-FORM')
      if (mobileSearchForm.classList.contains('FLOWACTIVE')) {
        const pickUpTime = document.getElementById('pickUpTime')
        if (pickUpTime) {
          setTimeout(() => {
            pickUpTime.click()
          }, 500)
        }
      }
    }

    handleFlowCancelDate = (e) => {
      e.preventDefault()
      this.closeDateMenu()
      const mobileSearchForm = document.getElementById('MOBILE-SEARCH-FORM')
      mobileSearchForm.classList.remove('FLOWACTIVE')
    }

    render() {
      const {
        isAge25Above,
        idForClick,
        pickupDate,
        dropOffDate,
        explanation,
        date,
        onDateChange,
        time,
        onTimeChange,
        label,
        after,
        t,
        i18n,
        ...props
      } = this.props
      const { hour, min } = time
      const selectedTime = timeConverter({ hour: hour, min: min })

      const locales = { es, it, de, fr }
      const language = i18n.resolvedLanguage || 'en'
      const formattedDate = date
        ? format(date, util.getFormat(language)[1], { locale: locales[language] }) // TODO: add a format function to utils date
        : 'Choose date'

      return (
        <DateAndTimeContainer {...props}>
          <DateAndTimeWrapper className='buttons'>
            {/* MOBILE DATE SELECT AND PICKER */}
            <Downshift onChange={onDateChange} isOpen={this.state.isDateOpen} id='test1113'>
              {({ getRootProps, getMenuProps, getToggleButtonProps }) => (
                <DateInputContainer {...getRootProps()}>
                  <DateInputWrapper isPageDynamic={this.props.isPageDynamic}>
                    <ButtonEl
                      id={idForClick ? idForClick : 'date'}
                      className='date-select'
                      {...getToggleButtonProps({ onClick: this.openDateMenu })}
                    >
                      <DateText mt={1}>{formattedDate}</DateText>
                    </ButtonEl>
                  </DateInputWrapper>
                  <ReactPortal wrapperId='date-field-with-range-v0'>
                    <MenuDS {...getMenuProps()} isOpen={this.state.isDateOpen}>
                      <FadeModal
                        isOpen={this.state.isDateOpen}
                        onClose={this.handleFlowCancelDate}
                        onClick={this.handleFlowCancelDate}
                      >
                        <ModalHeaderDS label={''} closeMenu={this.handleFlowCancelDate}>
                          <DateHeaderTitle>{t('homePage:pickUpDropOffTime')}</DateHeaderTitle>
                          <ModalSelectDS type='button' width={1}>
                            {util.formatDateTimeRangeWithoutTime(pickupDate, dropOffDate, i18n.resolvedLanguage)}
                          </ModalSelectDS>
                        </ModalHeaderDS>
                        <DatePickerWrapper>
                          <DateRangePicker />
                          <NextButtonWrapper>
                            <NextButton onClick={this.handleNextDate}>{t('homePage:next')}</NextButton>
                          </NextButtonWrapper>
                        </DatePickerWrapper>
                      </FadeModal>
                    </MenuDS>
                  </ReactPortal>
                </DateInputContainer>
              )}
            </Downshift>
            {/* MOBILE TIME SELECT AND PICKER */}
            <Downshift>
              {({ getRootProps, getToggleButtonProps }) => (
                <TimeInputContainer {...getRootProps()}>
                  <TimeInputWrapper
                    isPageDynamic={this.props.isPageDynamic}
                    id={explanation === 'Pick Up Hour' ? 'pickUpTime' : 'dropOffTime'}
                    onClick={this.openTimePopUp}
                  >
                    <El className='time-select' {...getToggleButtonProps()}>
                      <InputEl type='button' className='time-select' value={selectedTime} />
                    </El>
                  </TimeInputWrapper>
                  <TimeSelect
                    showPopUp={this.state.isTimeOpen}
                    isAge25Above={isAge25Above}
                    onTimeChange={onTimeChange}
                    title={explanation}
                    closeFunction={this.closeTimePopUp}
                    country={country}
                    defaultTime={selectedTime}
                  />
                </TimeInputContainer>
              )}
            </Downshift>
          </DateAndTimeWrapper>
        </DateAndTimeContainer>
      )
    }
  }
)`
  .buttons {
    width: 100%;
    padding: 0;
    color: ${themeGet('colors.grey')};
    .date-select {
      padding: 8px 0px;
      color: #525266;
      font-size: 18px !important;
      background-color: white;
      width: 80% !important;
      text-align: left;
    }
    .time-select {
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #525266;
      width: 75% !important;
      padding-right: 0%;
      padding-left: 3%;
      text-align: left;
      background-color: none;
      input {
        background-color: white;
        width: 100%;
        border: none;
      }
      &:hover {
        cursor: pointer;
      }
      .checkbox {
        bottom: 7px;
        left: 85%;
        height: 18px;
      }
    }
  }
`

export default withTranslation()(DateFieldWithRange)
