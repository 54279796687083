import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import IconInfo from './assets/icon-info.svg'

export { RadioUI } from '../../UI/Radio'
export { RadioGroup } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    padding: 0
  }
})

export const Text = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  cursor: pointer;
  ${({ $checked }) => $checked && `border-color: #0076FF !important;background: #F0F7FF;`}
`

export const RowWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
`

export const InsuranceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & button {
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none;
    background: url(${IconInfo});
    height: 20px;
    width: 20px;
  }
`

export const PriceInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`

export const PriceText = styled(Text)`
  font-weight: 500;
  line-height: 23px;
`

export const OldPriceText = styled(Text)`
  color: var(--Text-Hint, #b4b4c2);
  font-weight: 500;
  line-height: 23px;
  text-decoration: line-through;
`

export const Subtitle = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`

export const Discount = styled(Text)`
  color: #0076ff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  border-radius: 4px;
  border: 1px solid rgba(0, 118, 255, 0.15);
  background: rgba(0, 118, 255, 0.1);
  padding: 2px 5px;
`

export const BestValue = styled(Discount)`
  border-radius: 4px;
  border: 1px solid rgba(255, 125, 14, 0.25);
  background: #fff8f3;
  color: #ff7d0e;
`

export const InsuranceListWrapper = styled.div`
  & > div {
    border: 1px solid var(--borderGrey);
  }

  & > div:nth-child(1) {
    border-radius: 12px 12px 0 0;
  }

  & > div:nth-child(3) {
    border-radius: 0 0 12px 12px;
  }
`
