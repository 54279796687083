import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Grid
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { spacing, typography, display, flexbox, borders, palette } from '@material-ui/system'
import styled from 'styled-components'
import IconDraw from './assets/icon-draw.svg'

const getGap = ({ gap }) => `gap: ${(gap ?? 0) * 8}px;`
const getWhiteSpace = ({ whiteSpace }) => `white-space: ${whiteSpace ?? 'normal'}`

// shouldforwardprop is here or react complains
export const StyledGrid = styled(Grid).withConfig({ shouldForwardProp: (prop) => !prop.includes('border') })`
  ${getGap}
  ${spacing}
  ${borders}
  ${flexbox}
  ${palette}
`

export const Text = styled.p`
  margin: 0;
  font-weight: 600;
  color: #525266;
  line-height: normal;
  font-size: 16px;
  ${spacing}
  ${typography}
  ${palette}
  ${getWhiteSpace}
`

export const Span = styled.span`
  font-size: 18px;
  font-weight: 500;
  ${typography}
`

export const Image = styled.img`
  object-fit: ${(props) => props.fit || 'contain'};
  ${spacing}
  ${display}
  ${flexbox}
`

export const Anchor = styled.button`
  color: #ff7d0e;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
`

export const ConfirmButton = styled(Button)`
  color: white !important;
  border-radius: 4px;
  line-height: normal !important;
  background-color: #ff7d0e !important;
  padding: 10px 32px !important;
  margin-top: 30px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  min-width: 150px !important;
  ${flexbox}
`

export const useStyles = makeStyles({
  label: {
    '& .Mui-checked + .MuiFormControlLabel-label': {
      color: '#FF7D0E'
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 20,
      fontWeight: 600
    }
  }
})

export const StyledTextField = withStyles({
  root: (props) => ({
    '& .MuiOutlinedInput-root': {
      background: '#FBFBFD',
      borderRadius: 8
    },

    '& .MuiInputBase-multiline': {
      padding: 0
    },

    '& .MuiOutlinedInput-input': {
      padding: 12,
      fontSize: 15,
      fontWeight: 500,
      color: '#525266',
      lineHeight: '20px'
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #DFDFE7'
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #FF7D0E'
    },

    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
      fontSize: 15,
      color: props.customcolor ?? '#8E8EA4'
    },

    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
      color: '#FF7D0E'
    }
  })
})(TextField)

export const StyledAccordion = withStyles({
  root: {
    padding: 0,
    margin: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: 0
    }
  }
})(Accordion)

export const StyledAccordionSummary = withStyles({
  root: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 0
    }
  },
  content: {
    margin: 0,
    fontWeight: 600,
    fontSize: 16,
    color: '#525266',
    '&.Mui-expanded': {
      margin: 0
    }
  }
})(AccordionSummary)

export const StyledAccordionDetails = withStyles({
  root: {
    padding: 0,
    display: 'block'
  }
})(AccordionDetails)

export const StyledButton = withStyles({
  root: {
    fontSize: 18,
    lineHeight: 'normal',
    color: '#FF7D0E',
    fontWeight: '500',
    textDecorationLine: 'underline',
    padding: 0,
    margin: '24px auto',
    display: 'block'
  }
})(Button)

export const StyledButtonFilled = withStyles({
  root: {
    borderRadius: 8,
    border: '2px solid #FF7D0E',
    background: '#FF7D0E',
    color: 'white',
    padding: '8px 12px',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 'normal',
    flex: '1 1 0',
    '&:hover': {
      background: '#FF7D0E'
    }
  }
})(Button)

export const StyledButtonOutlined = withStyles({
  root: {
    background: 'white',
    color: '#FF7D0E',
    lineHeight: '24px',
    '&:hover': {
      background: 'white'
    }
  }
})(StyledButtonFilled)

export const StyledRadioGroup = withStyles({
  root: {
    gap: 12
  }
})(RadioGroup)

export const StyledFormControlLabel = withStyles({
  root: {
    gap: 10,
    margin: 0,
    '& .MuiSvgIcon-root': {
      height: 24,
      width: 24,
      '& > path': {
        transform: 'scale(1.20) translate(-2px, -2px)'
      }
    }
  },
  label: {
    color: '#525266',
    fontFamily: 'Quicksand',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 'normal'
  }
})(FormControlLabel)

export const StyledRadio = withStyles({
  root: {
    height: 24,
    width: 24,
    color: '#DFDFE7',
    background: '#EFF1F8'
  },
  checked: {
    color: '#FF7D0E !important'
  }
})(Radio)

export const ViewBreakdownButton = styled.button`
  margin-top: 4px;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  color: #696984;
  font-size: 15px;
  font-weight: 400;
  text-decoration-line: underline;
  margin: auto;
  margin-right: 0;
`

export const ManageButton = styled.button`
  cursor: pointer;
  margin: auto;
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ff7d0e;
  background: var(--White, #fff);
  color: #ff7d0e;
  font-size: 16px;
  font-weight: 400;
  & ::before {
    content: url(${IconDraw});
    height: 14px;
    width: 14px;
  }
`
