import styled from 'styled-components'
import { CardFlexEl, El, FlexEl, OrangeLink } from '../layout'
import util from '../../util'
import { Insurance, Seat } from '../svg'
import React, { Fragment } from 'react'
import { Info } from '../CarListing/CarCard'
import { themeGet } from 'styled-system'
import GA from '../../services/GA'
import { Form } from 'formik'
import { FadeModal } from '../Modal'
import { ImportantInfoModal } from './modals/ImportantInfoModal'
import { InclusionsListNonSS } from './InclusionsListNonSS'
import Loading from '../Loading'
import { ModalType, shouldShowTripleInsurance } from '../../routes/XO1'
import { InsuranceSelectorNonSS } from './InsuranceSelectorNonSS'
import { Upgrade } from './Upgrade'
import ReactLoading from 'react-loading'
import { theme } from '../../styles'
import { MdCheck as CheckIcon } from 'react-icons/md'
import { ReactComponent as IconLimited } from './../../util/images/icon_limited.svg'
import Cookies from 'js-cookie'
import Default from '../../util/images/placeholder-car.png'
import { ProtectionModal } from './modals/ProtectionModal'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Automatic } from '../../util/images/icon-automatic.svg'
import { ReactComponent as Manuel } from '../../util/images/icon-manual.svg'
import TermsAndConditionsModal from './../../components/TermsAndConditionsModal'
import { Dialog } from '@material-ui/core'

const FreeCancelLabel = styled.div`
  border-radius: 8px;
  background: ${(props) => props.$bgColor || '#d5f8e8'};
  padding: 12px 4px;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.$color || '#035830'};
  flex: 1;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  gap: 4px;
  justify-content: center;
  @media (max-width: 787px) {
    font-size: 12px;
    padding: 7px 4px;
    gap: 0px;
    border-radius: 5px;
    > svg {
      height: 16px;
      width: 16px;
      gap: 0;
    }
  }
`

const AncillarySection = styled.div`
  padding: 16px 12px 0 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > p {
    margin: 0 0 8px 0;
    color: #ff7d0e;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
`

export const CarDetailsCardNonSS = styled(
  ({
    carRentalProduct,
    ancillaryProducts,
    selectedAncillaries,
    updatedSelections,
    selectedInsurance,
    deselectedInsurance,
    modal,
    openModal,
    closeModal,
    isLoading,
    values,
    fromXO1WithoutSkyScanner = false,
    modalProtection,
    ...props
  }) => {
    const tripleInsurance = ancillaryProducts && shouldShowTripleInsurance(ancillaryProducts)
    const { t } = useTranslation()

    // This is needed for correct icon for transmisssion because it is based on english string matching
    const transmissonTranslateMap = ['Manuelle', 'Manual', 'Manuale']

    const filteredAncillaries = tripleInsurance
      ? ancillaryProducts.filter((ap) => ap.type !== 'Insurance')
      : ancillaryProducts

    const { car, supplier, extras } = carRentalProduct
    const isSixtInsured = supplier.name === 'Sixt' && extras?.insured === true
    let utmSource = Cookies.get('utm_source')
    const flexibleCancellationTranslationMap = ['flexible', 'flessibile']
    const isFreeCancel = flexibleCancellationTranslationMap.some((val) =>
      extras?.cancellationPolicy?.toLowerCase().includes(val)
    )

    const handleError = (e) => {
      e.target.src = Default
    }

    return (
      <CardFlexEl as={Form} className={props.className}>
        {!fromXO1WithoutSkyScanner && (
          <>
            <FlexEl ml='24px' mr='24px' className='top-container'>
              <FlexEl className='name-group-container'>
                <El className='card-title'>{t('xo1:carDetails')}</El>
                <El mt='12px'>{car.groupDescription}</El>
                <El mt='4px'>{car.name}</El>
              </FlexEl>
              <FlexEl className='total-price-container'>
                <img
                  src={util.toHttps(carRentalProduct.supplier.logoUrl)}
                  alt={'rent_carla_calendar_icon'}
                  className='supplier-logo'
                />
              </FlexEl>
            </FlexEl>
            <FlexEl className='body-container'>
              <FlexEl className='car-img-container'>
                <img className='car-img' src={util.toHttps(car.imageUrl)} alt={car.name} onError={handleError} />
              </FlexEl>
              <FlexEl className='info-container'>
                <Info
                  icon={transmissonTranslateMap.includes(car.transmissionDescription) ? <Manuel /> : <Automatic />}
                  description={car.transmissionDescription}
                />
                <Info icon={<Seat />} description={car.seatCount} />
                {carRentalProduct.extras.insured && (
                  <Info
                    icon={<Insurance />}
                    description={isSixtInsured ? t('carListing:protection') : t('carListing:insurance')}
                  />
                )}
              </FlexEl>
            </FlexEl>
          </>
        )}
        <El>
          {!ancillaryProducts ? (
            <El style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '12px 24px' }}>
              <ReactLoading
                className='loading-animation'
                type={'spin'}
                height={30}
                width={30}
                color={`${theme.colors.colorPrimary}`}
              />
            </El>
          ) : (
            <>
              <FlexEl className='inclusions-container'>
                {utmSource === 'skyscanner' && (
                  <FlexEl justifyContent='space-between' style={{ gap: '12px', marginBottom: '24px' }}>
                    {isFreeCancel && (
                      <FreeCancelLabel $color='#035830' $bgColor='#D5F8E8'>
                        <CheckIcon style={{ color: '#035830', fontSize: '24px' }} />
                        {t('xo1:freeCancel')}
                      </FreeCancelLabel>
                    )}
                    <FreeCancelLabel $color='#FF7D0E' $bgColor='#FDEFE9'>
                      <IconLimited />
                      {t('xo1:confirmation')}
                    </FreeCancelLabel>
                  </FlexEl>
                )}
                {!carRentalProduct.needsModification && (
                  <InclusionsListNonSS
                    inclusions={carRentalProduct.inclusions}
                    selectedAncillaries={selectedAncillaries}
                    ancillaryProducts={ancillaryProducts}
                    fromXO1WithoutSkyScanner={fromXO1WithoutSkyScanner}
                  />
                )}
                <OrangeLink
                  style={{ margin: '12px 0px 0px 0px', padding: 0 }}
                  onClick={() => {
                    openModal(ModalType.TC)
                    GA.openTermsConditions()
                  }}
                >
                  <u className='terms-and-conditions'>{t('xo1:termsAndConditions')}</u>
                </OrangeLink>
                <Dialog open={modal === ModalType.TC} onClose={closeModal} fullWidth maxWidth='md'>
                  <TermsAndConditionsModal onClose={closeModal} />
                </Dialog>
                {carRentalProduct.extras.importantInfo && (
                  <Fragment>
                    <OrangeLink onClick={() => openModal(ModalType.IMPORTANT_INFO)}>
                      <u>{t('xo1:importantInfo')}</u>
                    </OrangeLink>
                    <FadeModal
                      isOpen={modal === ModalType.IMPORTANT_INFO}
                      closable={true}
                      ok={true}
                      onClose={closeModal}
                      width={750}
                    >
                      <ImportantInfoModal importantInfo={carRentalProduct.extras.importantInfo} />
                    </FadeModal>
                  </Fragment>
                )}
              </FlexEl>
              {carRentalProduct.dependencies.brokerName !== 'Carla-HDSERV' && (
                <FlexEl className='additional-products-container'>
                  <FlexEl className='loading'>
                    {isLoading && (
                      <El my={25} mx='auto' width={50}>
                        <Loading />
                      </El>
                    )}
                  </FlexEl>
                  {ancillaryProducts && tripleInsurance && (
                    <InsuranceSelectorNonSS
                      insuranceProducts={ancillaryProducts.filter((ap) => ap.type === 'Insurance')}
                      selectedInsurance={selectedInsurance}
                      deselectedInsurance={deselectedInsurance}
                      currentSelections={selectedAncillaries}
                      modal={modal}
                      openModal={openModal}
                      closeModal={closeModal}
                    />
                  )}
                  <ProtectionModal
                    ancillaryProducts={ancillaryProducts.filter((ap) => ap.type === 'Insurance')}
                    selectedInsurance={selectedInsurance}
                    deselectedInsurance={deselectedInsurance}
                    currentSelections={selectedAncillaries}
                    isOpen={modalProtection}
                  />
                  <AncillarySection>
                    <p>{t('xo1:upgrade')}</p>
                    {ancillaryProducts &&
                      filteredAncillaries.length > 0 &&
                      filteredAncillaries.map((ap, i) => {
                        return (
                          <FlexEl
                            key={i}
                            className={i + 1 === filteredAncillaries.length ? 'final-insurance-card' : 'insurance-card'}
                          >
                            <Upgrade
                              ancillaryProduct={ap}
                              updatedSelections={updatedSelections}
                              selectedAncillaries={selectedAncillaries}
                            />
                          </FlexEl>
                        )
                      })}
                  </AncillarySection>
                </FlexEl>
              )}
            </>
          )}
        </El>
      </CardFlexEl>
    )
  }
)`
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  padding: 16px 0px 16px 0px;
  color: ${themeGet('colors.textBlack')};

  .card-title {
    color: ${themeGet('colors.colorPrimary')};
    font-size: 16px;
  }

  .body-container {
    padding: 10px 24px 12px 24px;
    border-bottom: solid 1px ${themeGet('colors.separatorGrey')};
  }

  .car-img {
    max-width: 100%;
    max-height: 144px;
    object-fit: contain;
  }

  .info-container {
    flex-flow: column;
    color: ${themeGet('colors.textBlack')};
    font-size: 15px;
    margin-left: 24px;
  }

  .name-group-container {
    flex-flow: column;
    font-size: 15px;
    font-weight: 500;
    flex-grow: 1;
  }

  .total-price-container {
    object-fit: contain;
  }

  .inclusions-container {
    flex-flow: column;
    padding: 12px 24px 0px 24px;
  }
  .additional-products-container {
    flex-flow: column;
  }

  .insurance-card {
    flex-flow: column;
    z-index: 1.5;
  }

  .final-insurance-card {
    flex-flow: column;
  }

  .trip-protection-card {
    flex-flow: column;
    padding: 12px 24px 12px 24px;
    border-bottom: solid 1px ${themeGet('colors.separatorGrey')};
  }
  .pom-card {
    flex-flow: column;
    padding: 12px 24px 0px 24px;
  }

  .supplier-logo {
    max-width: 150px;
    max-height: 40px;
  }

  .loading {
    border-bottom: solid 1px ${themeGet('colors.separatorGrey')};
  }
`
