import AppleBadgeES from '../util/images/badges/icon-apple-badge-es.svg'
import AppleBadgeEN from '../util/images/badges/icon-apple-badge-en.svg'
import AppleBadgeIT from '../util/images/badges/icon-apple-badge-it.svg'
import AppleBadgeFR from '../util/images/badges/icon-apple-badge-fr.svg'
import AppleBadgeDE from '../util/images/badges/icon-apple-badge-de.svg'
import GoogleBadgeES from '../util/images/badges/icon-google-badge-es.png'
import GoogleBadgeEN from '../util/images/badges/icon-google-badge-en.png'
import GoogleBadgeIT from '../util/images/badges/icon-google-badge-it.png'
import GoogleBadgeFR from '../util/images/badges/icon-google-badge-fr.png'
import GoogleBadgeDE from '../util/images/badges/icon-google-badge-de.png'

export default {
  apple: { es: AppleBadgeES, fr: AppleBadgeFR, en: AppleBadgeEN, it: AppleBadgeIT, de: AppleBadgeDE },
  google: { es: GoogleBadgeES, fr: GoogleBadgeFR, en: GoogleBadgeEN, it: GoogleBadgeIT, de: GoogleBadgeDE }
}
