import React, { useRef, useState } from 'react'
import * as styles from './styles'
import IconUnchecked from './assets/icon-unchecked.svg'
import IconChecked from './assets/icon-checked.svg'
import ProtectionPlanModal from '../XO1/modals/ProtectionPlanModal'
import money from '../../util/money'
import { useTranslation } from 'react-i18next'

const {
  useStyles,
  CardWrapper,
  RowWrapper,
  InsuranceWrapper,
  Title,
  Text,
  PriceInfo,
  PriceText,
  OldPriceText,
  Discount,
  BestValue,
  RadioUI,
  Subtitle
} = styles

export default function (props) {
  const { t: TRANSLATOR } = useTranslation()
  const classes = useStyles()
  const radioRef = useRef()
  const [show, setShow] = useState(false)
  const {
    index,
    value,
    checked,
    insurance: { title, subtitle, info, priceText, discountInfoText, basePriceText },
    onDeselect,
    dispatch,
    selectedAncillaries,
    setSelectedAncillaries
  } = props

  function handleClick(e) {
    e.stopPropagation()
    setShow(true)
  }

  return (
    <>
      <CardWrapper onClick={() => radioRef.current.click()} $checked={checked}>
        <RowWrapper>
          <InsuranceWrapper>
            <Title>
              <Text>{title}</Text>
              {checked && <button type='button' onClick={handleClick} />}
            </Title>
            <PriceInfo>
              <PriceText>{money.parsePriceText(priceText)}</PriceText>
              {!!discountInfoText && (
                <>
                  <OldPriceText>{money.parseBasePriceText(basePriceText)}</OldPriceText>
                  <Discount>
                    {money.parseDiscount(priceText)}% {TRANSLATOR('xo1:discount')}
                  </Discount>
                </>
              )}
              {index === 1 && <BestValue>{TRANSLATOR('xo1:bestValue')}</BestValue>}
            </PriceInfo>
          </InsuranceWrapper>
          <RadioUI
            value={value}
            classes={classes}
            icon={<img src={IconUnchecked} alt='icon' />}
            checkedIcon={<img src={IconChecked} alt='icon-checked' />}
            checked={checked}
            onChange={(e) => dispatch(setSelectedAncillaries(selectedAncillaries.concat(e.target.value)))}
            onClick={onDeselect}
            inputRef={radioRef}
          />
        </RowWrapper>
        {checked && <Subtitle>{subtitle}</Subtitle>}
      </CardWrapper>
      <ProtectionPlanModal show={show} title={title} info={info} onClose={() => setShow(false)} />
    </>
  )
}
