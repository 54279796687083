import styled from 'styled-components'
import { space } from 'styled-system'
import IconInfoBlue from '../../util/images/icon-info-blue.svg'

// Can add more parameter if need be
export const InfoTextWithLeftIcon = styled.p`
  padding: 8px;
  color: var(--textBlack);
  font-size: ${({ fontSize }) => fontSize || 14}px;
  font-weight: 500;
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  gap: ${({ gap }) => gap || 4}px;
  margin: 0;
  background-color: ${({ bg }) => bg || '#d5e8ff'};
  width: ${({ fullwidth }) => (fullwidth ? '100%' : 'fit-content')};
  border-radius: 12px;
  ${space}
  & ::before {
    content: url(${({ icon }) => icon || IconInfoBlue});
    ${({ size }) => size && `width: ${size}px; height: ${size}px;`}
    line-height: 0;
  }
`
