import React, { Fragment, useEffect, useState } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import loadable from '@loadable/component'
import { MdSearch } from 'react-icons/md'
import { Logo, MobileLogo } from '../components/svg'
import { El, FlexEl } from '../components/layout'
import BurgerIcon from '../components/BurgerIcon'
import { theme } from '../styles'
import GA from '../services/GA'
import { CarlaContext, useBarKey } from '../App'
import { connect } from 'react-redux'
import { setPromoCodeOpen } from './../redux/slices'
import { DesktopScreen, DefaultScreen } from './../components/common/Screens'
import Cookies from 'js-cookie'
import {
  OutlineButton,
  StyledMenu,
  MobileUlTagWrapper,
  NavLink,
  Buttons,
  NavbarContainer,
  NavbarWrapper,
  LogoLink,
  NavTagWrapper,
  UlTagWrapper,
  SimpleButton
} from './navigation.styles'
import LanguageMenu from '../components/HomePage/LanguageMenu'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useCheckCountry from '../hooks/useCheckCountry'

const DownloadApp = loadable(() => import('./DownloadApp'), {
  ssr: false
})

const mapStateToProps = (state) => ({
  promoCodeIsOpen: state.promoCodeOpen
})

/**
 * if user is in one of blog pages, show icon or outline button to redirect her to search page
 */
const BlogSearchButton = () => (
  <CarlaContext.Consumer>
    {({ location }) => {
      if (location.pathname.startsWith('/blog')) {
        return (
          <Fragment>
            <El
              show={[1, 1, 0]}
              as={RouterLink}
              to='/'
              p={'20px 0 10px 10px'}
              color='colorPrimary'
              onClick={GA.clickTopBlogSearchButton}
            >
              <MdSearch fontSize={32} />
            </El>
            <El show={[0, 0, 1]} pl={15}>
              <OutlineButton as={RouterLink} to='/' px={15} onClick={GA.clickTopBlogSearchButton}>
                <El as='span' mr='5px'>
                  Search for cars
                </El>
                <MdSearch fontSize={26} />
              </OutlineButton>
            </El>
          </Fragment>
        )
      }
      return <Fragment />
    }}
  </CarlaContext.Consumer>
)

const NavigationBar = ({ currentPage, location, ...props }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [showQR, setShowQR] = useState(false)
  const [shouldPersist, setShouldPersist] = useState(false)
  const [codeSize, setCodeSize] = useState(72)
  const [first, setFirst] = useState(true)
  const [leaving, setLeaving] = useState(false)
  const key = useBarKey()
  const [isSkyscannerActive, setIsSkyscannerActive] = useState(false)
  const { t } = useTranslation()

  // This is temporary. When full localization is achieved, this should be removed
  const [display, setDisplay] = useState(true)
  const language = useSelector((state) => state.user.language)

  const checkCountry = useCheckCountry()

  useEffect(() => {
    setDisplay(!['it', 'es', 'de', 'fr'].includes(language))
  }, [language])

  useEffect(() => {
    let utmSource = Cookies.get('utm_source')
    if (
      utmSource === 'skyscanner' &&
      (currentPage.pathname === '/reservation' || currentPage.pathname.includes('/rental-cars/'))
    ) {
      setIsSkyscannerActive(true)
    }
  }, [currentPage.pathname])

  //eslint-disable-next-line
  let currentPath = location.pathname == '/' ? true : false

  useEffect(() => {
    const all = document.getElementsByClassName('nav-link')
    for (let x = 0; x < all.length; x++) {
      const curr = all[x]
      if (curr.pathname === currentPage.pathname) {
        curr.classList.add('active')
      } else {
        curr.classList.remove('active')
      }
    }
  }, [currentPage])

  const toggleActive = (el) => {
    const all = document.getElementsByClassName('nav-link')
    for (let i = 0; i < all.length; i++) {
      if (all[i].pathname === el.currentTarget.pathname) {
        all[i].classList.add('active')
      } else {
        all[i].classList.remove('active')
      }
    }
    setMenuIsOpen(false)
  }

  const toggleMenu = () => {
    if (!menuIsOpen) {
      GA.openSidebar()
    }
    setMenuIsOpen(!menuIsOpen)
  }

  const closeMenu = () => {
    setMenuIsOpen(false)
  }

  const handleOpenMenu = () => {
    setMenuIsOpen(false)
    props.setPromoCodeOpen(true)
  }

  const handleEnter = () => {
    setShowQR(true)
    if (first) {
      setTimeout(() => {
        setCodeSize(144)
        setTimeout(() => {
          setCodeSize(72)
        }, 1000)
      }, 1500)
      setFirst(false)
    }
  }

  const handleClick = () => {
    GA.clickDownloadApp()
    setShouldPersist(!shouldPersist)
  }

  let hidingLayout = false
  const hiddenHeaderPagePath = [
    '/payment',
    '/bundle-reservation',
    '/bundle-payment',
    '/hotel-reservation',
    '/hotel-payment',
    '/availableAreas'
  ]
  const hiddenHeaderPagePathForMobile = ['/rental-cars/', '/hotel-results/', '/hotel-bundle/list']

  const hiddenHeaderPagePathOnDesktop = ['/reservation', '/payment']

  if (hiddenHeaderPagePathOnDesktop.includes(currentPage.pathname) || currentPage.pathname.includes('/rental-cars/')) {
    hidingLayout = true
  }
  const isHiddenMobileHeader =
    hiddenHeaderPagePath.includes(currentPage.pathname) ||
    hiddenHeaderPagePathForMobile.some((path) => currentPage?.pathname.includes(path))

  const navBarFor = (key) => {
    const variants = {
      REGULAR: (
        <>
          <DefaultScreen>
            {isHiddenMobileHeader || (currentPage.pathname === '/reservation' && !isSkyscannerActive) ? null : (
              <header>
                {/* MOBILE COLLAPSING MENU */}
                <StyledMenu
                  right
                  width='100%' // property for react-burger-menu (not styled)
                  isOpen={menuIsOpen}
                  pageWrapId='page-container'
                  outerContainerId='root'
                  customBurgerIcon={false}
                  customCrossIcon={false}
                >
                  <MobileUlTagWrapper>
                    {display && (
                      <li>
                        <NavLink onClick={toggleActive} className={'nav-link'} py={3} px={12} as={RouterLink} to='/'>
                          Car
                        </NavLink>
                      </li>
                    )}
                    {display && (
                      <li>
                        <NavLink
                          onClick={toggleActive}
                          className={'nav-link'}
                          py={3}
                          px={12}
                          as={RouterLink}
                          to='/hotels'
                        >
                          Hotel
                        </NavLink>
                      </li>
                    )}
                    {display && (
                      <li>
                        <NavLink
                          onClick={toggleActive}
                          className={'nav-link'}
                          py={3}
                          px={12}
                          as={RouterLink}
                          to='/hotel-bundle'
                        >
                          Bundle & Save
                        </NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink
                        onClick={toggleActive}
                        className={'nav-link'}
                        py={3}
                        px={12}
                        as={RouterLink}
                        to='/my-reservations'
                      >
                        {t('reservations:nav')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleActive}
                        className={'nav-link'}
                        py={3}
                        px={12}
                        as={RouterLink}
                        to='/contact'
                      >
                        {t('homePage:contactUs')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleActive}
                        className={'nav-link'}
                        py={3}
                        px={12}
                        as={RouterLink}
                        to='/blog/partnership-with-carla.html'
                      >
                        {t('homePage:partnerWithUs')}
                      </NavLink>
                    </li>
                    {currentPath && display && (
                      <li>
                        <Buttons onClick={handleOpenMenu} className={'nav-link'} py={3} mx={24}>
                          Promo Code
                        </Buttons>
                      </li>
                    )}
                    <li style={{ visibility: !checkCountry ? 'hidden' : 'visible' }}>
                      <NavLink
                        py={3}
                        px={12}
                        as='a'
                        href='https://onelink.to/uhy54z'
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={GA.clickDownloadApp}
                      >
                        {t('homePage:downloadApp')}
                      </NavLink>
                    </li>
                  </MobileUlTagWrapper>
                  {/*<NavLink onClick={closeMenu} py={3} px={12} as={RouterLink} to="/help">Help</NavLink>*/}
                </StyledMenu>
                {/* PLACEHOLDER */}
                <El height={theme.navbar.height} />
                {/* FIXED NAVBAR */}
                <NavbarContainer
                  id='NAVBAR-HOMEPAGE'
                  height={theme.navbar.height}
                  px={[15, 25, 15]}
                  style={{ height: '50px' }}
                >
                  <NavbarWrapper width={[1, 1, 1280]} justifyContent='space-between' alignItems='center'>
                    {/* LOGO */}
                    {!hidingLayout && (
                      <div>
                        <LanguageMenu textTransform='uppercase' />
                      </div>
                    )}
                    <LogoLink
                      to='/'
                      as={RouterLink}
                      onClick={() => {
                        closeMenu()
                        GA.clickNavLogo()
                      }}
                      mr={3}
                    >
                      <El show={[1, 1, 0]}>
                        <MobileLogo />
                      </El>
                    </LogoLink>
                    {/* NAVBAR RIGHT */}
                    {!hidingLayout && (
                      <FlexEl id='toggle-button-wrapper' alignItems='center'>
                        {/* DYNAMIC PART */}
                        <BlogSearchButton />
                        {/* BURGER */}
                        <BurgerIcon show={[1, 1, 0]} isOpen={menuIsOpen} onClick={toggleMenu} />
                      </FlexEl>
                    )}
                  </NavbarWrapper>
                </NavbarContainer>
              </header>
            )}
          </DefaultScreen>
          <DesktopScreen>
            <header>
              {/* PLACEHOLDER */}
              <El height={theme.navbar.height} />
              {/* FIXED NAVBAR */}
              <NavbarContainer
                id='NAVBAR-HOMEPAGE'
                height={theme.navbar.height}
                px={[15, 25, 15]}
                style={{ boxShadow: '0 2px 6px rgba(0,0,0,.1)' }}
              >
                <NavbarWrapper width={[1, 1, 1280]} justifyContent='space-between' alignItems='center'>
                  {/* LOGO */}
                  <LogoLink
                    to='/'
                    {...(!hidingLayout && { as: RouterLink })}
                    {...(isSkyscannerActive && { as: RouterLink })}
                    onClick={() => {
                      closeMenu()
                      if (!hidingLayout) GA.clickNavLogo()
                    }}
                    mr={3}
                    style={{ cursor: isSkyscannerActive ? 'pointer' : hidingLayout ? 'initial' : 'pointer' }}
                  >
                    <El show={[0, 0, 1]}>
                      <Logo />
                    </El>
                  </LogoLink>
                  {/* DESKTOP LINKS */}
                  {!hidingLayout && (
                    <NavTagWrapper show={[0, 0, 1]} width={1} justifyContent='space-between' px={3}>
                      <UlTagWrapper show={[0, 0, 1]} width={1} justifyContent='flex-end' alignItems='center' px={3}>
                        {display && (
                          <li>
                            <NavLink
                              onClick={toggleActive}
                              className={'nav-link'}
                              py={3}
                              mx={24}
                              as={RouterLink}
                              to='/'
                            >
                              Car
                            </NavLink>
                          </li>
                        )}
                        {display && (
                          <li>
                            <NavLink
                              onClick={toggleActive}
                              className={'nav-link'}
                              py={3}
                              mx={24}
                              as={RouterLink}
                              to='/hotels'
                            >
                              Hotel
                            </NavLink>
                          </li>
                        )}
                        {display && (
                          <li>
                            <NavLink
                              onClick={toggleActive}
                              className={'nav-link'}
                              py={3}
                              mx={24}
                              as={RouterLink}
                              to='/hotel-bundle'
                            >
                              Bundle & Save
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <NavLink
                            onClick={toggleActive}
                            className={'nav-link'}
                            py={3}
                            mx={24}
                            as={RouterLink}
                            to='/my-reservations'
                          >
                            {t('reservations:nav')}
                          </NavLink>
                        </li>
                      </UlTagWrapper>
                      <UlTagWrapper show={[0, 0, 1]} width={1} justifyContent='flex-end' alignItems='center' px={3}>
                        <li>
                          <LanguageMenu />
                        </li>
                        <li>
                          <NavLink
                            onClick={toggleActive}
                            className={'nav-link'}
                            py={3}
                            mx={24}
                            as={RouterLink}
                            to='/contact'
                          >
                            {t('homePage:contactUs')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={toggleActive}
                            className={'nav-link'}
                            py={3}
                            mx={24}
                            as={RouterLink}
                            to='/blog/partnership-with-carla.html'
                          >
                            {t('homePage:partnerWithUs')}
                          </NavLink>
                        </li>
                        {currentPath && display && (
                          <li>
                            <Buttons onClick={handleOpenMenu} className={'nav-link'} py={3} mx={24}>
                              Promo Code
                            </Buttons>
                          </li>
                        )}
                        {/* DOWNLOAD APP */}
                        <li>
                          <El
                            show={[0, 0, 1]}
                            pl={18}
                            style={{ position: 'relative', visibility: !checkCountry ? 'hidden' : 'visible' }}
                          >
                            <OutlineButton
                              rel='noopener noreferrer'
                              onMouseEnter={handleEnter}
                              onMouseLeave={() => {
                                if (!shouldPersist) {
                                  setLeaving(true)
                                }
                                setShowQR(false)
                                setTimeout(() => {
                                  setLeaving(false)
                                }, 500)
                              }}
                              onClick={handleClick}
                              mx={24}
                            >
                              {t('homePage:downloadApp')}
                            </OutlineButton>
                            <DesktopScreen>
                              <DownloadApp
                                showQR={showQR}
                                shouldPersist={shouldPersist}
                                codeSize={codeSize}
                                leaving={leaving}
                                setShowQR={setShowQR}
                                setCodeSize={setCodeSize}
                              />
                            </DesktopScreen>
                          </El>
                        </li>
                      </UlTagWrapper>
                    </NavTagWrapper>
                  )}
                  {/* NAVBAR RIGHT */}
                  {!hidingLayout && (
                    <FlexEl id='toggle-button-wrapper' alignItems='center'>
                      {/* DYNAMIC PART */}
                      <BlogSearchButton />
                      {/* BURGER */}
                    </FlexEl>
                  )}
                </NavbarWrapper>
              </NavbarContainer>
            </header>
          </DesktopScreen>
        </>
      ),

      PAYMENT: (
        <header>
          {/* MOBILE COLLAPSING MENU */}
          <StyledMenu
            right
            width='100%' // property for react-burger-menu (not styled)
            isOpen={menuIsOpen}
            pageWrapId='page-container'
            outerContainerId='root'
            customBurgerIcon={false}
            customCrossIcon={false}
          >
            <MobileUlTagWrapper>
              <li>
                <NavLink onClick={toggleActive} className={'nav-link'} py={3} px={12} as={RouterLink} to='/'>
                  Car
                </NavLink>
              </li>
              <li>
                <NavLink onClick={toggleActive} className={'nav-link'} py={3} px={12} as={RouterLink} to='/hotels'>
                  Hotel
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleActive}
                  className={'nav-link'}
                  py={3}
                  px={12}
                  as={RouterLink}
                  to='/my-reservations'
                >
                  Reservations
                </NavLink>
              </li>
              <li>
                <NavLink onClick={toggleActive} className={'nav-link'} py={3} px={12} as={RouterLink} to='/contact'>
                  {t('homePage:contactUs')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleActive}
                  className={'nav-link'}
                  py={3}
                  px={12}
                  as={RouterLink}
                  to='/blog/partnership-with-carla.html'
                >
                  {t('homePage:partnerWithUs')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  py={3}
                  px={12}
                  as='a'
                  href='https://onelink.to/uhy54z'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={GA.clickDownloadApp}
                >
                  {t('homePage:downloadApp')}
                </NavLink>
              </li>
            </MobileUlTagWrapper>
            {/*<NavLink onClick={closeMenu} py={3} px={12} as={RouterLink} to="/help">Help</NavLink>*/}
          </StyledMenu>
          {/* PLACEHOLDER */}
          <El height={theme.navbar.height} />
          {/* FIXED NAVBAR */}
          <NavbarContainer height={theme.navbar.height} px={[15, 25, 15]}>
            <FlexEl width={[1, 1, 1280]} justifyContent='space-between' alignItems='center'>
              {/* LOGO */}
              <LogoLink
                to='/'
                as={RouterLink}
                onClick={() => {
                  closeMenu()
                  GA.clickNavLogo()
                }}
                mr={3}
              >
                <El show={[1, 0, 0]}>
                  <MobileLogo />
                </El>
                <El show={[0, 1, 1]}>
                  <Logo />
                </El>
              </LogoLink>
            </FlexEl>
          </NavbarContainer>
        </header>
      ),

      NO_CARS: (
        <header>
          {/* MOBILE COLLAPSING MENU */}
          <StyledMenu
            right
            width='100%' // property for react-burger-menu (not styled)
            isOpen={menuIsOpen}
            pageWrapId='page-container'
            outerContainerId='root'
            customBurgerIcon={false}
            customCrossIcon={false}
          >
            <MobileUlTagWrapper>
              <li>
                <NavLink onClick={toggleActive} className={'nav-link'} py={3} px={12} as={RouterLink} to='/'>
                  Car
                </NavLink>
              </li>
              <li>
                <NavLink onClick={toggleActive} className={'nav-link'} py={3} px={12} as={RouterLink} to='/hotels'>
                  Hotel
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleActive}
                  className={'nav-link'}
                  py={3}
                  px={12}
                  as={RouterLink}
                  to='/my-reservations'
                >
                  My reservations
                </NavLink>
              </li>
              <li>
                <NavLink onClick={toggleActive} className={'nav-link'} py={3} px={12} as={RouterLink} to='/contact'>
                  {t('homePage:contactUs')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleActive}
                  className={'nav-link'}
                  py={3}
                  px={12}
                  as={RouterLink}
                  to='/blog/partnership-with-carla.html'
                >
                  {t('homePage:partnerWithUs')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  py={3}
                  px={12}
                  as='a'
                  href='https://onelink.to/uhy54z'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={GA.clickDownloadApp}
                >
                  {t('homePage:downloadApp')}
                </NavLink>
              </li>
            </MobileUlTagWrapper>
            {/*<NavLink onClick={closeMenu} py={3} px={12} as={RouterLink} to="/help">Help</NavLink>*/}
          </StyledMenu>
          {/* PLACEHOLDER */}
          <El height={theme.navbar.height.map((h) => h * 0.7)} />
          {/* FIXED NAVBAR */}
          <NavbarContainer height={theme.navbar.height.map((h) => h * 0.7)} px={[15, 5, 5]}>
            <FlexEl width={[1, 1, 1280]} justifyContent='space-between' alignItems='center'>
              {/* LOGO */}
              <LogoLink
                to='/'
                as={RouterLink}
                onClick={() => {
                  closeMenu()
                  GA.clickNavLogo()
                }}
                mr={3}
              >
                <El show={[1, 0, 0]}>
                  <MobileLogo />
                </El>
                <El show={[0, 1, 1]}>
                  <Logo />
                </El>
              </LogoLink>
              {/* DESKTOP LINKS */}

              <FlexEl show={[0, 1, 1]} width={1} justifyContent='flex-end' px={3}>
                <NavLink
                  py={4}
                  px={24}
                  as={RouterLink}
                  style={{ fontWeight: '600' }}
                  to='/blog/partnership-with-carla.html'
                >
                  {t('homePage:partnerWithUs')}
                </NavLink>
              </FlexEl>
              {/* DOWNLOAD APP */}
              <El show={[0, 1, 1]} pl={18}>
                <SimpleButton
                  as='a'
                  href='https://onelink.to/uhy54z'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={GA.clickDownloadApp}
                  px={18}
                >
                  {t('homePage:downloadApp')}
                </SimpleButton>
              </El>
              {/* NAVBAR RIGHT */}
              <FlexEl alignItems='center'>
                {/* DYNAMIC PART */}
                <BlogSearchButton />
                {/* BURGER */}
                <BurgerIcon show={[1, 0, 0]} isOpen={menuIsOpen} onClick={toggleMenu} />
              </FlexEl>
            </FlexEl>
          </NavbarContainer>
        </header>
      )
    }
    return variants[key]
  }

  return navBarFor(key)
}

export default connect(mapStateToProps, { setPromoCodeOpen })(withRouter(NavigationBar))
