import React, { useMemo } from 'react'
import InsuranceCard from './InsuranceCard'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedAncillaries } from '../../redux/slices'
import { InsuranceListWrapper, RadioGroup } from './styles'

export function InsuranceList(props) {
  const { insurances } = props
  const reordered = useMemo(() => [insurances[1], insurances[0], insurances[2]], [insurances])
  const ids = useMemo(() => insurances.map((insurance) => insurance.id), [insurances])

  const selectedAncillaries = useSelector((state) => state.ancillary.selectedAncillaries)
  const selectedValue = selectedAncillaries.find((id) => ids.includes(id))

  const dispatch = useDispatch()

  function handleSelect(e) {
    const id = e.target.value
    if (!selectedAncillaries.includes(id)) {
      const updated = selectedAncillaries.filter((id) => !ids.includes(id)).concat([id])
      dispatch(setSelectedAncillaries(updated))
    }
  }

  function handleDeselect() {
    const updated = selectedAncillaries.filter((id) => !ids.includes(id))
    dispatch(setSelectedAncillaries(updated))
  }

  return (
    <RadioGroup value={selectedValue} onChange={handleSelect}>
      <InsuranceListWrapper>
        {reordered.map(
          (insurance, index) =>
            insurance && (
              <InsuranceCard
                key={index}
                index={index}
                value={insurance.id}
                checked={selectedValue === insurance.id}
                insurance={insurance}
                onDeselect={handleDeselect}
                selectedAncillaries={selectedAncillaries}
                dispatch={dispatch}
                setSelectedAncillaries={setSelectedAncillaries}
              />
            )
        )}
      </InsuranceListWrapper>
    </RadioGroup>
  )
}
